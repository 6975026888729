var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form ma ma--bottom_sm"},[_c('control-input-common',{ref:"password",attrs:{"rules":"required|min:8","type":"password","class-name":"form__input form__input--space_right form__input--light-2","label-class":"form__label form__label--accent-1","input-label":_vm._('Пароль'),"required":true,"name":"password"},model:{value:(_vm.formdata.password),callback:function ($$v) {_vm.$set(_vm.formdata, "password", $$v)},expression:"formdata.password"}})],1),_c('div',{staticClass:"form ma ma--bottom_sm"},[_c('control-input-common',{ref:"passwordConfirm",attrs:{"rules":"required|min:8|confirmed:password","type":"password","class-name":"form__input form__input--space_right form__input--light-2","label-class":"form__label form__label--accent-1","input-label":_vm._('Повторите пароль'),"required":true,"name":"passwordConfirm"},model:{value:(_vm.formdata.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.formdata, "passwordConfirm", $$v)},expression:"formdata.passwordConfirm"}}),_c('validation-provider',{attrs:{"name":"nonFieldErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('div',{staticClass:"form ma ma--bottom_sm"},[_c('span',{staticClass:"control-hint control-hint--variant_1"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"uid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('div',{staticClass:"form ma ma--bottom_sm"},[_c('span',{staticClass:"control-hint control-hint--variant_1"},[_vm._v(_vm._s(_vm._("Идентификатор пользователя не валидный, попробуйте снова отправить письмо для восстановления пароля.")))])]):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"token"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('div',{staticClass:"form ma ma--bottom_sm"},[_c('span',{staticClass:"control-hint control-hint--variant_1"},[_vm._v(_vm._s(_vm._("Токен не валидный, попробуйте снова отправить письмо для восстановления пароля.")))])]):_vm._e()]}}],null,true)})],1),_c('bem-button',{staticClass:"de-text--size_2xs",attrs:{"width":"full","block":"ls-button","appearance":"yellow","disabled":_vm.isLoad},on:{"event":function($event){return _vm.validateBeforeSubmit()}}},[_vm._v(_vm._s(_vm._('Сохранить')))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }