var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ls-card ls-card--space_3sm ls-card--color_white ls-card--border_3sm-adaptive pos pos--relative"},[_c('ui-preloader',{class:{'is-active': _vm.isLoad},attrs:{"loader-color":"loader-wrapper__bg--accent-1","loader-class":"loader--variant_absolute"}}),_c('button',{staticClass:"modal-close modal-close--common",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$emit('close')}}}),_c('bem-text',{staticClass:"ma--top_3xs-xxl ma--bottom_3sm",attrs:{"tag":"p","size":"md","weight":"semi-bold"}},[_vm._v(_vm._s(_vm._("Добавить выполненную домашку")))]),_c('div',{staticClass:"ma ma--top_3xs-xxl ma--bottom_3sm"},[_c('p',{staticClass:"ls-text ls-text--color_coral ls-text--size_2xs ls-text--height_md"},[_vm._v(_vm._s(_vm._("При сдаче или пересдаче после"))+" "),_c('span',{staticClass:"ls-text ls-text--weight_semi-bold"},[_vm._v(_vm._s(_vm.objectItem.deadline))]),_vm._v("  "+_vm._s(_vm._("от максимального балла будет отниматься штраф за просрочку.")))])]),_c('validation-observer',{ref:"observer",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"ma ma--bottom_sm"},[_c('validation-provider',{attrs:{"rules":"required","name":"content","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-editor',{model:{value:(_vm.formdata.content),callback:function ($$v) {_vm.$set(_vm.formdata, "content", $$v)},expression:"formdata.content"}}),(errors[0])?_c('div',{staticClass:"form ma ma--bottom_sm"},[_c('span',{staticClass:"control-hint control-hint--variant_1"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"g-row g-row--appearance_spaced g-row--space_sm"},[_c('div',{staticClass:"g-cell g-cols g-cols--12 g-cols--6-sm g-cols--4-lg g-cols--narrow-xl"},[_c('div',{staticClass:"form pos pos--relative"},[_c('button',{staticClass:"ls-button ls-button--variant_accent-1 ls-button--width_full ls-button--variant_1"},[_vm._v(_vm._s(_vm._('Добавить материалы')))]),(_vm.uploadError)?_c('div',{staticClass:"form ma ma--bottom_sm"},[_c('span',{staticClass:"control-hint control-hint--variant_1"},[_vm._v(_vm._s(_vm.uploadError))])]):_vm._e(),_c('ui-upload',{attrs:{"type":"custom","formats":['jpeg', 'jpg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'zip', 'rar', 'mp4', 'avi', 'mp3'],"multiple":true},on:{"upload":function($event){return _vm.handleUpload($event)},"error":function($event){_vm.uploadError = $event}}})],1)]),_c('div',{staticClass:"g-cell g-cols g-cols--12"},[(_vm.formdata.files.length)?_c('div',{staticClass:"g-row g-row--appearance_spaced"},_vm._l((_vm.formdata.files),function(item,index){return _c('div',{staticClass:"g-cell g-cols"},[_c('div',{staticClass:"i-file"},[_c('div',{staticClass:"i-file__icon i-file__icon--variant_default"},[_c('i',{staticClass:"ls-icon ls-icon--type_file"})]),_c('span',{staticClass:"i-file__text"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"i-file__icon i-file__icon--variant_close",on:{"click":function($event){$event.preventDefault();return _vm.removeFile(index)}}},[_c('i',{staticClass:"ls-icon ls-icon--type_cross"})])])])}),0):_vm._e()])]),_c('validation-provider',{attrs:{"name":"nonFieldErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('div',{staticClass:"form ma ma--bottom_sm"},[_c('span',{staticClass:"control-hint control-hint--variant_1"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"homeTask"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('div',{staticClass:"form ma ma--bottom_sm"},[_c('span',{staticClass:"control-hint control-hint--variant_1"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"i-divider"}),_c('div',{staticClass:"g-row g-row--appearance_spaced g-row--justify_end"},[_c('div',{staticClass:"g-cell g-cols g-cols--6 g-cols--4-sm g-cols--3-lg g-cols--narrow-xl"},[_c('button',{staticClass:"ls-button ls-button--variant_accent-1-bordered ls-button--width_full",on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm._('Отмена')))])]),_c('div',{staticClass:"g-cell g-cols g-cols--6 g-cols--4-sm g-cols--3-lg g-cols--narrow-xl"},[_c('button',{staticClass:"ls-button ls-button--variant_yellow ls-button--width_full ls-button--variant_1",on:{"click":function($event){$event.preventDefault();return _vm.validateBeforeSubmit()}}},[_vm._v(_vm._s(_vm._('Готово')))])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }