import {
  createResource,
  patchResource,
  postResource,
} from '@/js/resource/resource'
import { prefixAPI } from '@/js/resource/api'

const MODEL_PREFIX = 'profile'

export const PROFILE_RETRIEVE = prefixAPI('/retrieve/{?id_user}', MODEL_PREFIX)
export const PROFILE_UPDATE = prefixAPI('/update/{id}/', MODEL_PREFIX)
export const PROFILE_UPDATE_MANAGER = prefixAPI('/update-manager/{id}/', MODEL_PREFIX)
export const PROFILE_LIST = prefixAPI('/list/{;filters*}{?limit,offset}', MODEL_PREFIX)
export const PROFILE_LIST_FILTERS = prefixAPI('/list-filter/', MODEL_PREFIX)
export const PROFILE_CREATE_OPTIONS = prefixAPI('/preform-create-manager/', MODEL_PREFIX)
export const PROFILE_CREATE = prefixAPI('/create-manager/', MODEL_PREFIX)
export const PROFILE_CREATE_CHILD = prefixAPI('/create-child/', MODEL_PREFIX)
export const PROFILE_NEW_EMAIL = prefixAPI('/new-email/{id}/', MODEL_PREFIX)

export const profileRetrieveResource = createResource(PROFILE_RETRIEVE)
export const profileUpdateResource = createResource(PROFILE_UPDATE, patchResource)
export const profileUpdateManagerResource = createResource(PROFILE_UPDATE_MANAGER, patchResource)
export const profileListResource = createResource(PROFILE_LIST)
export const profileListFiltersResource = createResource(PROFILE_LIST_FILTERS)
export const profileCreateOptionsResource = createResource(PROFILE_CREATE_OPTIONS)
export const profileCreateResource = createResource(PROFILE_CREATE, postResource)
export const profileCreateChildResource = createResource(PROFILE_CREATE_CHILD, postResource)
export const profileNewEmailResource = createResource(PROFILE_NEW_EMAIL, postResource)
