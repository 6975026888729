import {
  createResource,
  postResource,
  getResource,
} from '@/js/resource/resource'

import { GROUPS } from './paths'

export const groupsCatalogResource = createResource(
  GROUPS.CATALOG_CARDS, getResource
)

export const groupsCatalogOnlineResource = createResource(
  GROUPS.CATALOG_CARDS_ONLINE, getResource
)

export const groupsFiltersResource = createResource(
  GROUPS.FILTERS, getResource
)

export const createGroupRequestResource = createResource(
  GROUPS.CREATE_REQUEST, postResource
)

export const groupListByStudentResource = createResource(
  GROUPS.LIST_BY_STUDENT, getResource
)

export const groupRatingResource = createResource(
  GROUPS.RATING, getResource
)

export const groupCalendarResource = createResource(
  GROUPS.CALENDAR, getResource
)
