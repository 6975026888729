import './public-path'
import '@/js/validation'
import '@/js/scripts'
import '@/js/directives'

import Vue from 'vue'
import I18n from 'vue-i18n'
import Viewer from 'viewerjs'
import Router from 'vue-router'
import { Store } from 'vuex'
import { install } from '@/install'
import { createApp } from '@/app'
import { renderer } from '@/js/components/renderer'
import { createTakeTestApp } from '@/js/components/TakeTest/app'

import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

import './styles/index.sass'

window.Viewer = Viewer

Vue.config.productionTip = false

Vue.use(install)

const appEl = document.querySelector('#app')
const taketestAppEl = document.querySelector('#app-taketest')

let app

if (appEl) {
  app = createApp({
    Vue,
    I18n,
    Router,
    Store,
    renderer,
  }).app

  app.$mount(appEl)
}

if (taketestAppEl) {
  app = createTakeTestApp({
    Vue,
    I18n,
    Router,
  }).app

  app.$mount(taketestAppEl)
}

extend('phone_length', {
  validate(value) {
    const phoneLength = 13
    return phoneLength >= value.replace(/\D/g, '').toString().length
  },
  message() {
    return app._('Input a valid length of phone')
  },
})

extend('custom_email', {
  validate(value) {
    const CUSTOM_EMAIL_REGEXP = /^(([a-zA-Z\-_0-9^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return CUSTOM_EMAIL_REGEXP.test(value)
  },
  message() {
    return app._('The field must be a valid email address.')
  },
})

extend('required', {
  ...rules.required,
  message() {
    return app._('This field is required.')
  },
})

extend('alpha_spaces', {
  ...rules.alpha_spaces,
  message() {
    return app._('The field can contain only letters.')
  },
})

extend('email', {
  ...rules.email,
  message() {
    return app._('The field must be a valid email address.')
  },
})

extend('min', {
  ...rules.min,
  message(value, sec) {
    return `${app._('The field must contain at least')} ${sec.length} ${app._('symbols')}`
  },
})

extend('min_value', { ...rules.min_value, message: app._('Minimum value is {min}.') });

extend('max', {
  ...rules.max,
  message(value, sec) {
    return `${app._('The field must contain not more than')} ${sec.length} ${app._('symbols')}`
  },
})

extend('confirmed', {
  ...rules.confirmed,
  message() {
    return app._('Values do not match.')
  },
})

extend('numeric', {
  ...rules.numeric,
  message() {
    return app._('The field must be a number.')
  },
})

extend('digitMethod', {
  validate(value) {
    return /^[0-9]*$/i.test(value)
  },
  message() {
    return app._('Поле должно быть целым числом.')
  },
})

extend('url_validate', {
  validate(value) {
    const REGEXP = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    return REGEXP.test(value)
  },
  message() {
    return app._('Please enter a valid URL.')
  },
})

extend('edrpou', {
  validate(value) {
    return /^\d{8}$/.test(value) || /^\d{10}$/.test(value)
  },
  message() {
    return app._('ЕДРПОУ имеет не валидный формат.')
  },
})

extend('latin', {
  validate(value) {
    return /^[a-zA-Z `-]+$/.test(value)
  },
  message() {
    return app._('The field can only contain Latin characters.')
  },
})
extend('phone_is_valid', {
  validate(value, arg) {
    return arg[0]
  },
  message() {
    return app._('Введіть коректний номер телефону')
  },
})
