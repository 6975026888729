<template lang="pug">
include ../mixins
validation-observer(
  tag="form"
  ref="observer"
  v-slot="{ errors }"
  @submit.prevent="validateBeforeSubmit"
)
  +b.form.ma--bottom_sm
    control-input-common(
      autofocus
      rules="custom_email|required"
      type="email"
      class-name="form__input form__input--light-2"
      label-class="form__label form__label--accent-1"
      :input-label="_('E-mail')"
      v-model="formdata.email"
      :required="true"
      name="email"
    )
  +b.form.ma--bottom_sm
    control-input-common(
      rules="required|min:8"
      ref='password'
      type="password"
      v-model="formdata.password"
      class-name="form__input form__input--space_right form__input--light-2"
      label-class="form__label form__label--accent-1"
      :input-label="_('Пароль')"
      :required="true"
      name="password"
    )
  validation-provider(
    v-slot="{errors}"
    name="nonFieldErrors"
  )
    +b.form.ma.--bottom_sm(v-if="errors[0]")
      +b.SPAN.control-hint.--variant_1 {{ errors[0] }}
  row.ma--bottom_3sm(justify="end")
    cell
      modal-handler(
        component-name="password-reset-modal"
        modal-class="modal-common modal--size_sm"
      )
        template(#default="{ handler }")
          +b.ls-link.--color_accent-1.--size_3xs(
            @click="handler"
          ) {{_('Восстановить пароль')}}
  bem-button.de-text--size_2xs(
    width="full"
    block="ls-button"
    appearance="yellow"
    :disabled="isLoad"
    @event="validateBeforeSubmit()"
  ) {{_('Войти')}}
</template>

<script>
import { loginResource } from '@/js/services/auth.service'
import FormMixin from '@/js/mixins/form.mixin'
import { REDIRECTS } from '@md/users/permissions'
import { eraseCookie } from '@/js/resource/cookie'

export default {
  mixins: [FormMixin],

  props: {
    needRedirect: {},
  },

  data() {
    return {
      formdata: {},
    }
  },

  methods: {
    async send(data) {
      const copyData = await this.prepareData({ ...data })
      return loginResource.execute({}, copyData).then(res => {
        const { data: { item: { role } } } = res
        eraseCookie('user_group_id')
        eraseCookie('user_stream_id')
        if (this.needRedirect) {
          window.location = this.$router.resolve({ name: REDIRECTS[role] }).href
        } else {
          window.location.reload()
        }
      })
    },
  },
}
</script>
