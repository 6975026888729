<template lang="pug">
  include ../../mixins
  +b.pos--relative
    +b.i-action.--position_top.--position_left.--size_sm
      +b.I.ls-icon--type_search
    control-input-common(
      v-model='query'
      class-name="form__input form__input--light-3 form__input--space_left-sm"
      label-class="form__label form__label--accent-1"
      autocomplete='off'
      type='text'
      :placeholder='labels.query',
      @input='submit'
    )
    +b.hint(
      v-if="searchHint"
      v-tooltip.hover.ios="{content: searchHint, trigger: 'click hover'}"
    )
      +e.icon
        +b.I.ls-icon.--type_hint
</template>
<script>
import {
  isEmpty,
} from '@aspectus/vue-utils'

export default {
  name: 'SimpleSearch',
  props: ['value', 'searchHint'],
  data() {
    return {
      query: '',
      labels: {
        query: this._('Пошук'),
      },
    }
  },
  watch: {
    value(nval) {
      this.query = this.normalizeFrom(nval)
    },
  },
  mounted() {
    this.query = this.normalizeFrom(this.value)
  },
  methods: {
    resetValue() {
      this.query = ''
    },

    submit() {
      const length = 2
      const zero = 0
      if (this.query.length >= length) {
        this.$emit('input', [this.query])
        return
      }
      if (this.query.length === zero) {
        this.$emit('input', [])
      }
    },
    normalizeFrom(value) {
      if (isEmpty(value)) return ''
      const query = value
      return query
    },
  },
}
</script>
