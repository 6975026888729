import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const List = () => import('./views/List')

const ROUTE_NAME = 'profile:document:'

const p = name => ROUTE_NAME + name

const LIST = p('list')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([
          'parent', 'company', 'child', 'employee',
        ], options),
      },
    }),
    { path: '*', redirect: { name: LIST } },
  ]
}
