import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const Display = () => import('./views/Display')
const List = () => import('./views/List')

const ROUTE_NAME = 'profile:notification:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const CREATE = p('create')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([
          'parent', 'company', 'child', 'employee', 'manager', 'teacher',
        ], options),
      },
    }),
    route('settings/', Display, CREATE, {
      meta: {
        accessConfig: accessPermission([
          'parent', 'company', 'child', 'employee', 'manager', 'teacher',
        ], options),
      },
    }),
    { path: '*', redirect: { name: LIST } },
  ]
}
