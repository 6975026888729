// ios scroll
const body = document.querySelector('body')
let scrollPosition = 0
const zero = 0

export const scrollUnlock = () => {
  body.classList.remove('scroll-lock')
  body.classList.add('scroll-unlock')
  window.scrollTo(zero, scrollPosition)
}

export const scrollLock = () => {
  scrollPosition = window.pageYOffset
  body.classList.add('scroll-lock')
  body.classList.remove('scroll-unlock')
}
