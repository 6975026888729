import { accessMeta } from '@md/permissions'

export const REDIRECTS = {
  parent: 'profile:dashboard:list-common',
  company: 'profile:dashboard:list-common',
  child: 'profile:dashboard:list-common',
  employee: 'profile:dashboard:list-common',
  teacher: 'profile:dashboard:list-teacher',
  manager: 'profile:orders:list',
}

const { userType } = window

const initialRoute = { name: REDIRECTS[userType] }

export const permissionChecker = perms => perms.includes(userType)

export const accessPermission = (permissions, options) => accessMeta(
  permissionChecker(permissions), initialRoute, options
)
