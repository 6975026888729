<template lang="pug">
  include ../mixins
  div
    +b.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    +b.ls-text.--variant_center.--height_xs.--size_1.--weight_semi-bold {{ title }}
    bem-text.ma--top_sm(
      v-if="text"
      tag="p"
      color="main"
      size="sm"
      height="xs"
      variant="center"
    ) {{ text }}
    +b.ma.--top_sm
      +b.g-row.--justify_center
        +b.g-cell.g-cols--8
          +b.BUTTON.ls-button.--variant_yellow.--width_full.--variant_6(
            @click.prevent="$emit('close')"
          ) {{_('Понятно')}}
</template>

<script>
export default {
  name: 'success-modal',
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
}
</script>
