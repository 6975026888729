<template lang="pug">
include ../mixins
+b.ls-card--space_3sm.--color_white.--border_3sm-adaptive.pos--relative
  ui-preloader(
    :class="{'is-active': isLoad}"
    loader-color='loader-wrapper__bg--accent-1'
    loader-class="loader--variant_absolute"
  )
  +b.modal-close--common.BUTTON(
    tabindex="-1"
    @click="$emit('close')"
  )
  bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
    tag="p"
    size="md"
    weight="semi-bold"
  ) {{_("Восстановление пароля")}}
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    +b.form.ma--bottom_sm
      control-input-common(
        autofocus
        rules="custom_email|required"
        type="email"
        class-name="form__input form__input--light-2"
        label-class="form__label form__label--accent-1"
        :input-label="_('Е-mail')"
        v-model="formdata.email"
        :required="true"
        name="email"
      )
    validation-provider(
      v-slot="{errors}"
      name="nonFieldErrors"
    )
      +b.form.ma.--bottom_sm(v-if="errors[0]")
        +b.SPAN.control-hint.--variant_1 {{ errors[0] }}
    bem-button.de-text--size_2xs(
      width="full"
      block="ls-button"
      appearance="yellow"
      :disabled="isLoad"
      @event="validateBeforeSubmit()"
    ) {{_('Восстановить пароль')}}
</template>

<script>
import { passwordResetResource } from '@/js/services/auth.service.js'
import FormMixin from '@/js/mixins/form.mixin.js'

export default {
  mixins: [FormMixin],

  data() {
    return {
      formdata: {},
    }
  },

  methods: {
    async send(data) {
      const copyData = await this.prepareData({ ...data })
      return passwordResetResource.execute({}, copyData).then(res => {
        this.handleResponse({ res, modal: true })
      })
    },
  },
}
</script>
