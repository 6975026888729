import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const ListTeacher = () => import('./views/roles/teacher/List')
const ListCommon = () => import('./views/roles/common/List')

const ROUTE_NAME = 'profile:dashboard:'

const p = name => ROUTE_NAME + name

const LIST_TEACHER = p('list-teacher')
const LIST_COMMON = p('list-common')

export function createRoutes(options) {
  return [
    route('list/teacher/', ListTeacher, LIST_TEACHER, {
      meta: {
        accessConfig: accessPermission([
          'teacher',
        ], options),
      },
    }),
    route('list/', ListCommon, LIST_COMMON, {
      meta: {
        accessConfig: accessPermission([
          'parent', 'company', 'child', 'employee',
        ], options),
      },
    }),
  ]
}
